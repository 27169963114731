<template>
  <div id="task-info" v-cloak v-loading.fullscreen.lock="loading">
    <div class="container">
      <div class="header flex-between">
        <h1 class="titH">
          <p v-if="is_view == 1 && id > 0">
            {{ $t("taskManagement.taskInfo") }}
            <span :class="[getStatusClass(allData.task_status), 'tit-status']">
              - {{ allData.task_status | formateStatus }}
            </span>
          </p>
          <p v-else-if="is_view != 1 && id > 0 && allData.task_status != 4">
            {{ $t("taskInfo.edit") }}
          </p>
          <p v-else>
            {{ $t("taskInfo.new") }}
          </p>
        </h1>
        <template
          v-if="
            is_view == 1 &&
            allData.permission > 1 &&
            rightShow('CrmTask@taskInfo')
          "
        >
          <div>
            <!-- <div  class="edit-btn hand" v-if="rightShow('CrmTask@taskInfo')" @click="editTask(id)">
                        <i class="iconfont icon-bianji yellow-text"></i>
                        <span><tags:lang>编辑任务</tags:lang></span>
                    </div> -->
            <zc-icon-btn
              :content="$t('taskInfo.start')"
              btn_type="success"
              v-if="taskStatus === 5"
              icon="icon-queren"
              @click="singleOperation(2, id)"
            ></zc-icon-btn>
            <zc-icon-btn
              :content="$t('taskInfo.complete')"
              btn_type="success"
              v-if="taskStatus === 1"
              icon="icon-queren"
              @click="finishTask(id)"
            ></zc-icon-btn>
            <zc-icon-btn
              :content="$t('taskInfo.edit')"
              btn_type="primary"
              v-if="taskStatus === 1 || taskStatus === 4 || taskStatus === 5"
              icon="icon-bianji"
              @click="editTask(id)"
            ></zc-icon-btn>
            <zc-icon-btn
              :content="$t('taskManagement.addSubTask')"
              btn_type="info"
              v-if="taskStatus === 1 || taskStatus === 4 || taskStatus === 5"
              icon="icon-tianjia1"
              @click="singleOperation(3, id)"
            ></zc-icon-btn>
            <zc-icon-btn
              :content="$t('taskInfo.copy')"
              btn_type="warning"
              v-if="taskStatus === 1 || taskStatus === 4 || taskStatus === 5"
              icon="icon-xiangmu"
              @click="singleOperation(4, id)"
            ></zc-icon-btn>
            <zc-icon-btn
              :content="$t('taskInfo.delete')"
              btn_type="danger"
              v-if="
                taskStatus === 1 ||
                taskStatus === 2 ||
                taskStatus === 4 ||
                taskStatus === 5
              "
              icon="icon-huishouzhan"
              @click="singleOperation(7, id)"
            ></zc-icon-btn>
            <zc-icon-btn
              :content="$t('taskInfo.active')"
              btn_type="info"
              v-if="taskStatus === 2"
              icon="icon-xuanzhong"
              @click="singleOperation(5, id)"
            ></zc-icon-btn>
            <zc-icon-btn
              :content="$t('taskInfo.restore')"
              btn_type="info"
              v-if="taskStatus === 3"
              icon="icon-lvzhou_zhongzhi"
              @click="singleOperation(6, id)"
            ></zc-icon-btn>
          </div>
        </template>
      </div>
      <div class="direction-c">
        <div class="left-box">
          <div class="task-item" v-if="sourceId != 0">
            <span class="tit">{{ $t("taskInfo.source") }}：</span>
            <span
              class="yellow-text source-name"
              @click="viewOther(sourceId, sourceType, sourceOther)"
              >{{ sourceName }}</span
            >
          </div>
          <div class="task-item flex-a-center">
            <span class="tit" v-if="is_view != 1">
              <span class="red">*</span>
              {{ $t("taskInfo.title") }}：
            </span>
            <span class="task_title" v-if="is_view == 1">
              <template
                v-if="Date.parse(form.want_end_time) < new Date().getTime()"
              >
                <el-tooltip
                  effect="dark"
                  :content="$t('taskInfo.overdue')"
                  placement="top-start"
                >
                  <span class="red-text task_title ellipsis">
                    <span v-if="['1', '2'].indexOf(sourceType) >= 0"
                      >CRM:{{ form.task_title }}</span
                    >
                    <span
                      v-else-if="
                        ['3', '4', '5', '6', '7', '8', '9'].indexOf(
                          sourceType
                        ) >= 0
                      "
                    >
                      ERP:{{ form.task_title }}
                    </span>
                    <span v-else>
                      {{ form.task_title }}
                    </span>
                  </span>
                </el-tooltip>
              </template>
              <span v-else class="task_title ellipsis">
                <span v-if="['1', '2'].indexOf(sourceType) >= 0"
                  >CRM:{{ form.task_title }}</span
                >
                <span
                  v-else-if="
                    ['3', '4', '5', '6', '7', '8', '9'].indexOf(sourceType) >= 0
                  "
                >
                  ERP:{{ form.task_title }}
                </span>
                <span v-else>
                  {{ form.task_title }}
                </span>
              </span>
            </span>
            <div v-else class="flex-a-center task-name_box">
              <span class="prefix-span" v-if="sourceId > 0">
                <div v-if="['1', '2'].indexOf(sourceType) >= 0">CRM:</div>
                <div
                  v-show="
                    ['3', '4', '5', '6', '7', '8', '9'].indexOf(sourceType) >= 0
                  "
                >
                  ERP:
                </div>
              </span>
              <el-input
                v-model="form.task_title"
                size="small"
                :class="[sourceId > 0 ? 'task-tit——input' : 'w268']"
              ></el-input>
            </div>
          </div>
          <div class="mb5" v-if="is_view == 1">
            <template v-if="sourceType == 1"
              >{{ $t("taskInfo.text") }}:</template
            >
            <template v-if="sourceType == 2"
              >{{ $t("taskInfo.text1") }}:</template
            >
            <template
              v-if="
                ['3', '4', '5', '6', '7', '8', '9'].indexOf(sourceType) >= 0
              "
              >{{ $t("taskInfo.text3") }}:</template
            >
          </div>
          <div class="task-item flex">
            <span class="tit" v-if="is_view != 1">
              <span class="red">*</span>
              {{ $t("taskInfo.details") }}：
            </span>

            <div
              v-if="is_view == 1 && id > 0"
              v-html="form.task_content"
              class="task-content"
            >
              <div v-if="form.task_content == ''">
                {{ $t("projectInfo.no") }}
              </div>
            </div>
            <div v-else class="editor-outbox">
              <div class="task-source_tit">
                <template v-if="sourceType == 1">{{
                  $t("taskInfo.text")
                }}</template>
                <template v-if="sourceType == 2">{{
                  $t("taskInfo.text1")
                }}</template>
                <template
                  v-if="
                    ['3', '4', '5', '6', '7', '8', '9'].indexOf(sourceType) >= 0
                  "
                  >{{ $t("taskInfo.text3") }}</template
                >
              </div>
              <div id="editor"></div>
            </div>
          </div>
          <div class="edit-Box" v-if="is_view != 1">
            <el-upload
              :accept="uploadAccept"
              class="upload-box"
              :limit="5"
              :data="uploadParam"
              action="/home/CrmTask/fileUpload"
              ref="upload"
              :auto-upload="false"
              :file-list="form.path"
              :on-change="uploadOnchange"
              :on-success="successUpload"
              :multiple="true"
              :on-exceed="exceed"
              :on-remove="RemoveFile"
            >
              <span slot="trigger" class="mr24">
                <i class="el-icon-upload yellow-text"></i>
                {{ $t("taskInfo.uploadFile") }}
              </span>
            </el-upload>
            <span>
              <el-popover placement="left" width="200" trigger="click">
                <div
                  class="handel-inner"
                  v-for="(userItem, index) in userGroup"
                  :key="index"
                >
                  <el-popover
                    placement="right-end"
                    width="158"
                    v-model="tipVisibles[index]"
                    trigger="hover"
                  >
                    <div class="operation_inner">
                      <div
                        class="hand handel-inner"
                        @click="assignUser(item, index)"
                        v-for="(item, index2) in userItem.children"
                        :key="index2"
                      >
                        <span class="ellipsis user-label">{{
                          item.label
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="hand flex-a-center flex-between"
                      slot="reference"
                    >
                      <span class="ellipsis user-label">{{
                        userItem.label
                      }}</span>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </el-popover>
                </div>
                <span slot="reference" class="mr24 hand">
                  <span class="yellow-text">@</span>
                  <span>
                    {{ $t("taskInfo.someone") }}
                  </span>
                </span>
              </el-popover>
            </span>

            <span @click="addTaskList('auto')" class="hand">
              <i class="el-icon-circle-plus-outline yellow-text"></i>
              <span>
                {{ $t("taskInfo.autoAddList") }}
              </span>
            </span>
          </div>
          <div v-if="id > 0 && attachments" class="task-item flex">
            <span class="tit" style="min-width: auto"
              >{{ $t("taskInfo.attachment") }}：</span
            >
            <div class="attachments-box">
              <p
                v-for="(attachItem, attachIndex) in attachments"
                class="flex-a-center"
                :key="attachIndex"
              >
                <span
                  :class="[
                    attachItem.isCache ? 'isCacheIcon' : 'noCacheIcon',
                    'download-icon',
                  ]"
                  @click="downloadAtt(attachItem)"
                  >{{ attachItem.name }}
                </span>
                <i
                  class="el-icon-download hand"
                  v-if="is_view === 1"
                  @click="downloadAtt(attachItem)"
                ></i>
                <span
                  v-else
                  @click="delAttach(attachIndex)"
                  class="red-text ml10"
                  >{{ $t("delete") }}</span
                >
              </p>
            </div>
          </div>
          <div class="add-task_box flex-between" v-if="is_view != 1">
            <div @click="addTaskList" class="hand mb20">
              <i class="iconfont icon-tianjia yellow-text"></i>
              <span>
                {{ $t("taskInfo.addTaskList") }}
              </span>
            </div>
            <div v-if="is_view != 1">
              <el-switch
                class="ml5"
                v-model="form.is_list"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
              <span class="font">
                <i class="el-icon-info"></i>
                {{ $t("taskInfo.enableOrDisableList") }}
              </span>
            </div>
          </div>
          <div v-if="form.is_list === true && is_view != 1">
            <template v-if="form.list_data.length">
              <draggable
                v-model="form.list_data"
                group="list"
                @start="drag = true"
                @end="drag = false"
                class="draggable-item"
                v-bind="dragOptions"
              >
                <div
                  v-for="(item, index) in form.list_data"
                  class="list-item"
                  :key="index"
                >
                  <div class="flex flex-between mb16">
                    <div>
                      <span class="red">*</span>
                      <span class="mr5">{{ index + 1 }}.</span>
                      <el-input
                        size="small"
                        v-model="item.list_name"
                        :placeholder="$t('taskInfo.text4')"
                        class="w268"
                      >
                      </el-input>
                    </div>
                    <i
                      class="iconfont icon-qingkong yellow-text hand"
                      @click="deleteTaskList(index)"
                    ></i>
                  </div>
                  <div class="step-box">
                    <draggable
                      v-model="item.step_list"
                      group="step"
                      @start="drag = true"
                      @end="drag = false"
                      class="draggable-item"
                      v-bind="dragOptions"
                    >
                      <div
                        v-for="(stepItem, stepIndex) in item.step_list"
                        class="step-item flex-between"
                        :key="stepIndex"
                      >
                        <div class="flex-a-center">
                          <el-popover
                            placement="top-start"
                            width="20"
                            class="tip-popover"
                            trigger="hover"
                          >
                            <span>{{ $t("taskInfo.text5") }}</span>
                            <i
                              slot="reference"
                              class="iconfont icon-tishi tip-icon"
                            ></i>
                          </el-popover>
                          <template
                            v-if="rightShow('CrmTask@changeListStatus')"
                          >
                            <div
                              @click="changeStatus(index, stepIndex)"
                              class="hand flex-a-center"
                            >
                              <i
                                class="el-icon-success active-dot ml5 mr5"
                                v-if="stepItem.step_status == '4'"
                              ></i>
                              <i
                                class="el-icon-circle-check ml5 mr5"
                                v-else
                              ></i>
                              <span class="mr10">
                                {{ index + 1 }}.{{ stepIndex + 1 }}
                              </span>
                            </div>
                          </template>
                          <template v-else>
                            <div class="flex-a-center">
                              <i
                                class="el-icon-success active-dot ml5 mr5"
                                v-if="stepItem.step_status == '4'"
                              ></i>
                              <i
                                class="el-icon-circle-check ml5 mr5"
                                v-else
                              ></i>
                              <span class="mr10">
                                {{ index + 1 }}.{{ stepIndex + 1 }}
                              </span>
                            </div>
                          </template>
                          <el-input
                            size="small"
                            @input="isAlreadyList($event)"
                            v-model="stepItem.label"
                            :placeholder="$t('taskInfo.text6')"
                          ></el-input>
                          <div class="assign-user_box flex-a-center">
                            <img
                              :src="stepItem.user_avatar"
                              v-if="
                                stepItem.user_avatar &&
                                stepItem.user_avatar !== '' &&
                                stepItem.user_avatar !== null
                              "
                            />
                            <span class="ellipsis" :title="stepItem.user_name">
                              {{ stepItem.user_name }}</span
                            >
                          </div>
                        </div>
                        <div class="flex-a-b">
                          <template
                            v-if="
                              stepItem.user_name == '' ||
                              stepItem.user_name == undefined
                            "
                          >
                            <el-popover
                              placement="right"
                              width="300"
                              trigger="click"
                            >
                              <div class="user-option_box">
                                <span
                                  v-for="item in allUserOptions"
                                  :key="item.id"
                                  :class="[
                                    item.id === stepItem.step_at
                                      ? 'check-user'
                                      : '',
                                  ]"
                                  @click="assignUserRow(index, stepIndex, item)"
                                >
                                  <span :title="item.name" class="ellipsis">{{
                                    item.name
                                  }}</span>
                                  <i class="el-icon-check"></i>
                                </span>
                              </div>
                              <span slot="reference" class="font hand">
                                {{ $t("taskInfo.assign") }}
                              </span>
                            </el-popover>
                          </template>
                          <template v-else>
                            <span
                              @click="cancelAssign(index, stepIndex)"
                              class="font hand"
                            >
                              {{ $t("taskInfo.cancelAssign") }}
                            </span>
                          </template>
                          <i
                            class="el-icon-close hand ml10"
                            @click="deleteRow(index, stepIndex)"
                          ></i>
                        </div>
                      </div>
                    </draggable>
                    <div class="add-step_btn" @click="addRow(index)">
                      {{ $t("taskInfo.addStep") }}
                    </div>
                  </div>
                </div>
              </draggable>
            </template>
          </div>
          <div v-if="is_view == 1 && allData.is_list == true">
            <div
              v-for="(item, index) in form.list_data"
              :key="index"
              :class="[is_view == 1 && id > 0 ? 'no-hover' : '', 'list-item']"
            >
              <div class="flex-a-center mb16">
                <span class="list_tit"
                  >{{ $t("taskInfo.list") }}{{ index + 1 }}:
                  {{ item.list_name }}</span
                >
                <template v-if="item.step_list.length">
                  <el-progress
                    :stroke-width="9"
                    :percentage="getPercentage(index)"
                    class="progress"
                  ></el-progress>
                  <span class="ml10 finish-num"
                    >{{ getFinishNum(index) }}/{{
                      form.list_data[index].step_list.length
                    }}</span
                  >
                </template>
              </div>
              <div
                v-for="(stepItem, stepIndex) in item.step_list"
                :key="stepIndex"
                class="mb10 flex"
              >
                <template v-if="allData.is_delete == 0">
                  <div
                    @click="changeStatus(index, stepIndex)"
                    v-if="rightShow('CrmTask@changeListStatus')"
                    class="hand"
                  >
                    <i
                      class="el-icon-success active-dot ml5 mr5"
                      v-if="stepItem.step_status == '4'"
                    ></i>
                    <i class="el-icon-circle-check ml5 mr5" v-else></i>
                    <span class="mr10">
                      {{ $t("taskInfo.step") }}{{ index + 1 }}.{{
                        stepIndex + 1
                      }}：
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div class="flex-a-center">
                    <i
                      class="el-icon-success active-dot ml5 mr5"
                      v-if="stepItem.step_status == '4'"
                    ></i>
                    <i class="el-icon-circle-check ml5 mr5" v-else></i>
                    <span class="mr10">
                      {{ $t("taskInfo.step") }}{{ index + 1 }}.{{
                        stepIndex + 1
                      }}：
                    </span>
                  </div>
                </template>
                <span class="step-span"> {{ stepItem.label }}</span>
                <img
                  :src="stepItem.user_avatar"
                  class="user-avatar"
                  v-if="stepItem.step_at > 0 && stepItem.user_avatar"
                />
                <span class="assign-user_name">{{ stepItem.user_name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right-box">
          <div class="task-item flex-a-center">
            <span class="tit">
              <span class="red" v-show="sourceType == 2">*</span>
              {{ $t("taskInfo.estStartTime") }}
            </span>
            <template v-if="is_view == 1">
              {{
                allData.want_start_time
                  ? allData.want_start_time
                  : $t("taskManagement.unfinished")
              }}
            </template>
            <template v-else>
              <el-date-picker
                v-model="form.want_start_time"
                size="small"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm:ss"
                :placeholder="$t('taskInfo.estStartTime')"
                :picker-options="pickerOptions0"
              >
              </el-date-picker>
            </template>
          </div>
          <div class="task-item flex-a-center" v-if="is_view == 1">
            <span class="tit"> {{ $t("taskInfo.actStartTime") }}： </span>
            <template>
              {{
                allData.true_start_time === "0000-00-00 00:00:00"
                  ? $t("projectInfo.no")
                  : allData.true_start_time
              }}
            </template>
          </div>
          <div class="task-item flex-a-center">
            <span class="tit"> {{ $t("taskInfo.estEndTime") }}： </span>
            <template v-if="is_view == 1">
              {{
                allData.want_end_time
                  ? allData.want_end_time
                  : $t("taskManagement.unfinished")
              }}
            </template>
            <template v-else>
              <el-date-picker
                v-model="form.want_end_time"
                type="datetime"
                size="small"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm:ss"
                :placeholder="$t('taskInfo.estEndTime')"
                :picker-options="pickerOptions1"
              >
              </el-date-picker>
            </template>
          </div>
          <div class="task-item flex-a-center" v-if="is_view == 1">
            <span class="tit"> {{ $t("taskInfo.actEndTime") }}： </span>
            <template>
              {{
                allData.true_end_time === "0000-00-00 00:00:00"
                  ? $t("projectInfo.no")
                  : allData.true_end_time
              }}
            </template>
          </div>
          <div class="task-item flex-a-center">
            <span class="tit disabled-text">
              <span v-if="is_view != 1" class="red">*</span>
              {{ $t("taskManagement.createUser") }}：
            </span>
            <template>
              <img :src="allData.createUserPhoto" class="user-avatar" />
              <span class="user-name">{{ allData.createUserName }}</span>
            </template>
          </div>
          <div class="flex-a-center task-item">
            <span class="tit"> {{ $t("taskManagement.leader") }}： </span>
            <template v-if="is_view == 1">
              <template v-if="chargeGroup && chargeGroup.length > 0">
                <span
                  v-for="(item, index) in chargeGroup"
                  class="mr32 flex-a-center"
                  :key="index"
                >
                  <img :src="item.photo" class="user-avatar" />
                  <span class="user-name">{{ item.name }}</span>
                </span>
              </template>
              <template v-else class="user-name">{{
                $t("projectInfo.no")
              }}</template>
            </template>
            <template v-else>
              <el-cascader
                class="w378"
                size="small"
                v-model="chargeGroup"
                :options="chargeUserGroup"
                :show-all-levels="false"
                :props="{ multiple: true }"
                v-if="is_view != 1"
              ></el-cascader>
            </template>
          </div>
          <div class="flex-a-center task-item">
            <span class="tit"> {{ $t("taskManagement.participant") }}： </span>
            <template v-if="is_view == 1">
              <div
                v-if="joinGroup && joinGroup.length > 0"
                class="user-img_box"
              >
                <span
                  v-for="(item, index) in joinGroup"
                  class="mr32 flex-a-center"
                  :key="index"
                >
                  <img :src="item.photo" class="user-avatar" />
                  <span class="user-name">{{ item.name }}</span>
                </span>
              </div>
              <template v-else class="user-name">{{
                $t("projectInfo.no")
              }}</template>
            </template>
            <template v-else>
              <el-cascader
                class="w378"
                size="small"
                v-model="joinGroup"
                :options="joinUserGroup"
                :show-all-levels="false"
                :props="{ multiple: true }"
                v-if="is_view != 1"
              ></el-cascader>
            </template>
          </div>
          <div class="flex-a-center task-item">
            <span class="tit"> {{ $t("taskManagement.observer") }}： </span>
            <template v-if="is_view == 1">
              <div
                v-if="watchGroup && watchGroup.length > 0"
                class="user-img_box"
              >
                <span
                  v-for="(item, index) in watchGroup"
                  class="mr32 flex-a-center"
                  :key="index"
                >
                  <img :src="item.photo" class="user-avatar" />
                  <span class="user-name">{{ item.name }}</span>
                </span>
              </div>
              <template v-else class="user-name">{{
                $t("projectInfo.no")
              }}</template>
            </template>
            <template v-else>
              <el-cascader
                class="w378"
                size="small"
                v-model="watchGroup"
                :options="watchUserGroup"
                :show-all-levels="false"
                :props="{ multiple: true }"
                v-if="is_view != 1"
              >
                <template slot-scope="{ node, data }">
                  <span
                    :class="[
                      { 'disable-span': node.isLeaf && data.is_disabled },
                    ]"
                  >
                    {{ data.label }}
                  </span>
                </template>
              </el-cascader>
            </template>
          </div>
          <div class="task-item flex-a-center">
            <span class="tit">
              {{ $t("taskManagement.currentProject") }}：
            </span>
            <template v-if="is_view == 1 || (id > 0 && allData.pid > 0)">
              <div v-if="allData.project_name && allData.project_id">
                <el-tag @click="view(allData.project_id, 2)">{{
                  allData.project_name
                }}</el-tag>
              </div>
              <div v-else>
                {{ $t("projectInfo.no") }}
              </div>
            </template>
            <template v-else>
              <el-select
                v-model="form.project_id"
                class="w378"
                filterable
                clearable
                @change="changeProject"
                ref="projectSelect"
                :placeholder="$t('taskInfo.text8')"
                size="small"
              >
                <div v-if="projectOptions.length < 1">
                  {{ $t("taskInfo.no") }}
                </div>
                <div v-else>
                  <el-option
                    v-for="item in projectOptions"
                    :key="String(item.id)"
                    :label="item.project_name"
                    :value="String(item.id)"
                  >
                  </el-option>
                </div>
              </el-select>
            </template>
          </div>
          <div class="task-item flex-a-center">
            <span class="tit"> {{ $t("taskManagement.tagsName") }}： </span>
            <template v-if="is_view == 1">
              <template v-if="form.tags && form.tags.length > 0">
                <el-tag
                  class="mr10"
                  v-for="item in form.tags"
                  :key="item.name"
                  :color="item.value"
                  effect="dark"
                >
                  {{ item.tag_name }}
                </el-tag>
              </template>
              <template v-else> {{ $t("projectInfo.no") }} </template>
            </template>
            <template v-else>
              <div id="only-change-tags">
                <el-cascader
                  class="tags-cascader w378"
                  v-model="form.tags"
                  size="small"
                  :key="iskeyShowLabel"
                  :options="tagsGroup"
                  :show-all-levels="false"
                  ref="myCascader"
                  :props="{ multiple: true }"
                  @expand-change="handleExpandChange"
                  @visible-change="handleExpandChange"
                  @change="handleChange"
                ></el-cascader>
              </div>
              <el-button
                v-if="rightShow('CrmTask@getTaskLabel')"
                size="small"
                @click="fn"
                class="add-user ml10"
                icon="el-icon-plus"
              >
              </el-button>
            </template>
          </div>
          <div v-show="allData.pid == 0 || !id" class="task-item flex-a-center">
            <span class="tit"> {{ $t("taskInfo.subTask") }}： </span>
            <template v-if="is_view == 1">
              <template v-if="form.sub_task_ids.length > 0">
                <span v-for="item in allData.subTaskRes" :key="item.id">
                  <span class="mr5 flex-a-center">
                    <el-tag @click="view(item.id, 1)">{{
                      item.task_title
                    }}</el-tag>
                  </span>
                </span>
              </template>
              <template v-else> {{ $t("projectInfo.no") }} </template>
            </template>
            <template v-else>
              <el-select
                v-model="form.sub_task_ids"
                multiple
                size="small"
                class="w378"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="it in subTaskOptions"
                  filterable
                  :key="String(it.id)"
                  :disabled="it.disabled"
                  :label="it.task_title"
                  :value="String(it.id)"
                >
                  <span
                    :class="[
                      { 'green-text': it.task_status == 4 },
                      { 'gray-text': it.is_delete == 1 },
                    ]"
                  >
                    {{ it.task_title }}
                  </span>
                </el-option>
              </el-select>
            </template>
          </div>
          <div v-show="allData.pid > 0" class="task-item flex-a-center">
            <span class="tit"> {{ $t("taskInfo.parentTask") }}： </span>
            <template v-if="is_view == 1 || id > 0">
              <div v-if="allData.sup_task_title && allData.sup_id">
                <el-tag @click="view(allData.sup_id, 1)">{{
                  allData.sup_task_title
                }}</el-tag>
              </div>
              <div v-else>
                {{ $t("projectInfo.no") }}
              </div>
            </template>
          </div>

          <div class="task-item flex-a-center">
            <span class="tit"> {{ $t("taskInfo.associatedTasks") }}： </span>
            <template v-if="is_view == 1">
              <div v-if="allData.link_task_title && allData.link_id">
                <el-tag @click="view(allData.link_id, 1)">{{
                  allData.link_task_title
                }}</el-tag>
              </div>
              <div v-else>
                {{ $t("projectInfo.no") }}
              </div>
            </template>
            <template v-else>
              <el-select
                v-model="form.link_task_id"
                class="w378"
                filterable
                clearable
                :placeholder="$t('taskInfo.text9')"
                size="small"
              >
                <div v-if="taskOptions.length < 1">
                  {{ $t("taskInfo.no") }}
                </div>
                <div v-else>
                  <el-option
                    v-for="item in taskOptions"
                    :key="String(item.id)"
                    :label="item.task_title"
                    :value="String(item.id)"
                  >
                    <span
                      :class="[
                        { 'green-text': item.task_status == 4 },
                        { 'gray-text': item.is_delete == 1 },
                      ]"
                    >
                      {{ item.task_title }}
                    </span>
                  </el-option>
                </div>
              </el-select>
            </template>
          </div>
        </div>
      </div>
      <div v-show="is_view == 1">
        <template v-show="allData.permission >= 0">
          <h2 class="comment-tit">{{ $t("taskInfo.comment") }}</h2>
          <div class="comment-container mb40">
            <div class="input-wrapper" v-show="showMainComment">
              <el-input
                class="gray-bg-input"
                v-model="inputComment"
                type="textarea"
                :rows="6"
                size="small"
                autofocus
                :placeholder="$t('taskInfo.text10')"
              >
              </el-input>
              <div class="btn-control">
                <el-button
                  class="comment-btn"
                  type="warning"
                  size="small"
                  @click="commitComment(0)"
                  v-if="allData.permission >= 0"
                >
                  {{ $t("taskInfo.submit") }}
                </el-button>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div class="comment-container" v-show="allData.commentList">
            <div
              class="comment"
              v-for="(item, index) in allData.commentList"
              :key="index"
            >
              <div class="flex-between">
                <div class="flex-a-center">
                  <img
                    class="avatar mr5"
                    :src="item.from_avatar"
                    width="36"
                    height="36"
                  />
                  <div class="user-name">{{ item.from_name }}</div>
                </div>
                <div class="time">{{ item.create_time }}</div>
              </div>
              <div class="flex-between flex-a-center">
                <div class="content">{{ item.content }}</div>
                <span
                  class="yellow-text hand"
                  @click="showCommentInput(item)"
                  v-show="allData.permission >= 0"
                >
                  {{ $t("taskInfo.reply") }}
                </span>
              </div>
              <div class="reply">
                <div
                  class="flex-between replay-item"
                  v-for="(reply, replyIndex) in item.reply"
                  :key="replyIndex"
                >
                  <div class="reply-content">
                    <span class="user-name">{{ reply.from_name }}</span>
                    <span>
                      <span class="yellow-text">{{
                        $t("taskInfo.reply")
                      }}</span>
                      <span class="user-name">{{ reply.to_name }}：</span>
                    </span>
                    <span class="reply-content_detail">{{
                      reply.content
                    }}</span>
                  </div>
                  <span>
                    <span>{{ reply.create_time }}</span>
                    <span
                      class="yellow-text hand ml5"
                      @click="showCommentInput(item, reply)"
                      v-show="allData.permission >= 0"
                      >{{ $t("taskInfo.reply") }}</span
                    >
                  </span>
                </div>
                <transition name="fade">
                  <div
                    class="input-wrapper"
                    v-if="showItemId === item.id && !showMainComment"
                  >
                    <el-input
                      class="gray-bg-input"
                      v-model="inputComment"
                      type="textarea"
                      :rows="3"
                      size="small"
                      autofocus
                      :placeholder="$t('taskInfo.text7')"
                    >
                    </el-input>
                    <div class="btn-control">
                      <el-button
                        class="cancel mr10"
                        @click="cancel"
                        size="small"
                      >
                        {{ $t("cancel") }}
                      </el-button>
                      <el-button
                        class="comment-btn"
                        type="warning"
                        size="small"
                        @click="commitComment(item.id)"
                        v-if="allData.permission >= 0"
                      >
                        {{ $t("confirm") }}
                      </el-button>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="log-container" v-if="logData.length && is_view == 1">
        <div class="flex-between tit-top">
          <h1 class="log-tit">
            {{ $t("taskInfo.log") }}
          </h1>
          <div
            class="more hand"
            @click="showMore = !showMore"
            v-if="logData.length >= 6"
          >
            {{ showMore ? " " : "more" }}
            <i
              :class="[showMore ? 'el-icon-arrow-right' : 'el-icon-arrow-down']"
            ></i>
          </div>
        </div>
        <div :class="{ 'log-h-container': !showMore }">
          <div
            class="log-item"
            v-for="(logItem, logItemIndex) in logData"
            :key="logItemIndex"
          >
            <span class="log-time">{{ logItem.log_time }}</span>
            <p class="log-info">
              {{ logItem.admin_name }} {{ logItem.action_desc }}
            </p>
          </div>
        </div>
      </div>

      <div class="handel-box flex-center" v-if="is_view != 1">
        <zc-icon-btn
          :content="$t('cancel')"
          btn_type="warning"
          btn_class="search_button cancel-btn"
          icon="icon-guanbi"
          v-if="!id || is_view != 1"
          @click="cancelTag"
        ></zc-icon-btn>
        <zc-icon-btn
          :content="$t('save')"
          btn_type="success"
          btn_class="search_button"
          icon="icon-baocuncaogao"
          v-if="(!id || is_view != 1) && rightShow('CrmTask@taskInfoPut')"
          @click="submit(allData.task_status, 'save')"
        ></zc-icon-btn>
        <zc-icon-btn
          :content="$t('projectInfo.saveAndStart')"
          btn_type="primary"
          btn_class="search_button"
          icon="icon-tijiao"
          v-if="
            ((is_view != 1 && [1, 2].indexOf(allData.task_status) >= 0) ||
              !id) &&
            rightShow('CrmTask@taskInfoPut')
          "
          @click="submit(3, 'go')"
        >
        </zc-icon-btn>
      </div>
    </div>
    <el-dialog
      :title="$t('taskInfo.text11')"
      :visible.sync="setSinglVisible"
      width="400px"
      v-move
    >
      <div class="flex-center">
        <div v-show="subTaskShow">
          <el-select
            v-model="selectTaskArray"
            multiple
            :placeholder="$t('taskInfo.text12')"
            size="mini"
            class="mr10"
          >
            <el-option
              v-for="item in subTaskOptions"
              :key="item.id"
              :label="item.task_title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div slot="footer">
        <el-button
          v-if="rightShow('CrmTask@applicationTask')"
          size="mini"
          @click="setSinglVisible = false"
        >
          {{ $t("cancel") }}
        </el-button>
        <el-button
          v-if="rightShow('CrmTask@applicationTask')"
          type="primary"
          size="mini"
          @click="singlApplication(5)"
        >
          {{ $t("confirm") }}
        </el-button>
        <!-- <zc-icon-btn :content="$t('cancel')" btn_type="cancel-btn" btn_class="search_button"
                         icon="icon-guanbi" v-if="rightShow('CrmTask@applicationTask')"
                         @click="setSinglVisible = false"></zc-icon-btn>
            <zc-icon-btn content="<tags:lang>确定</tags:lang>" btn_type="primary" btn_class="search_button"
                         icon="icon-tijiao" v-if="rightShow('CrmTask@applicationTask')"
                         @click="singlApplication(5)"></zc-icon-btn> -->
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      center
      class="tips-dialog"
      :visible.sync="tipsDialogVisible"
      width="300px"
    >
      <span>没有查看任务权限</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tipsConfirm">{{
          $t("taskInfo.returnToTaskList")
        }}</el-button>
      </span>
    </el-dialog>
    <zc-crm-cus-window
      :is_open="is_open_window_crm_cus"
      open_path=""
      page="0"
      call_back="getData"
    >
    </zc-crm-cus-window>
  </div>
</template>

<script>
import "@/plugins/move";
import { plus, divide } from "@/utils/handel-number";
import { Post } from "@/utils/common";
import { toMessage } from "@/utils/message";
import ZcIconBtn from "@/components/ZcIconBtn";
import ZcCrmCusWindow from "@/components/ZcCrmCusWindow";
import draggable from "vuedraggable";
import E from "wangeditor";
export default {
  components: {
    ZcIconBtn,
    draggable,
    ZcCrmCusWindow,
  },
  name: "TaskInfo",
  data() {
    return {
      showMainComment: true,
      commentsData: [],
      taskStatus: "",
      setSinglVisible: false,
      subTaskShow: false,
      selectTaskArray: [],
      setSinglId: "",
      commentList: [],
      attachments: {},
      replyToId: 0,
      replyId: 0,
      inputComment: "",
      showItemId: "",
      loading: true,
      isShowLinkTask: false,
      editor: null,
      logData: [],
      allData: {},
      active: 0,
      iskeyShowLabel: 0,
      id: 0,
      // is_view: Number({$is_view})
      is_view: 0,
      is_show_join_user: false,
      is_show_watch_user: false,
      allUserOptions: [],
      tagOptions: [],
      projectOptions: [],
      taskOptions: [],
      subTaskOptions: [],
      tipVisibles: [],
      is_open_window_crm_cus: false,
      link_task_title: "",
      selectedAll: false,
      project_name: "",
      infiledList: [],
      inServForm: [],
      form: {
        task_title: "",
        task_content: "",
        list_data: [],
        is_list: false,
        want_start_time: "",
        want_end_time: "",
        // create_user_id: "{$adminId}",
        create_user_id: "",
        charge_user_ids: "",
        join_user_ids: "",
        watch_user_ids: "",
        project_id: "",
        task_tags: [],
        sub_task_ids: [],
        link_task_id: "",
        pid: "",
        path: [],
        tags: [],
      },
      stepData: {
        list_name: "",
        step_list: [
          {
            label: "",
            step_at: 0,
            step_status: 2,
            step_index: 0,
          },
        ],
      },
      statusOptions: [
        {
          val: 1,
          name: this.$t("taskManagement.unfinished"),
        },
        {
          val: 2,
          name: this.$t("taskManagement.notStart"),
        },
        {
          val: 3,
          name: this.$t("taskManagement.working"),
        },
        {
          val: 4,
          name: this.$t("taskManagement.completed"),
        },
      ],
      tagsGroup: [
        {
          value: 1,
          label: this.$t("projectInfo.blueTag"),
          children: [],
        },
        {
          value: 2,
          label: this.$t("projectInfo.redTag"),
          children: [],
        },
        {
          value: 3,
          label: this.$t("projectInfo.yellowTag"),
          children: [],
        },
        {
          value: 4,
          label: this.$t("projectInfo.orangeTag"),
          children: [],
        },
        {
          value: 5,
          label: this.$t("projectInfo.greenTag"),
          children: [],
        },
      ],
      currentIndex: "",
      allTags: {},
      hasTags: {},
      userGroup: [],
      chargeUserGroup: [],
      joinUserGroup: [],
      watchUserGroup: [],
      chargeGroup: [],
      joinGroup: [],
      watchGroup: [],
      changeStoreVisible: false,
      beforeStorageValue: "",
      afterStorageValue: "",
      isDisable: false,
      uploadAccept:
        ".xlsx, .xls, .pdf, .png, .jpg, .doc, .docx, .rar, .zip, .jpeg", //上传限制文件
      size: 5 * 1024,
      uploadParam: {
        str: new Date().getTime(),
      },
      file_atta: {},
      is_already_list: false,
      pickerOptions0: {
        // disabledDate: (time) => {
        // }
      },
      pickerOptions1: {
        // disabledDate: (time) => {
        // }
      },
      // sourceName: "{$source_name}",
      // sourceId: "{$source_id}",
      // sourceType: "{$source_type}",
      // sourceOther: "{$source_other}",
      sourceName: "",
      sourceId: "",
      sourceType: "",
      sourceOther: "",
      showMore: false,
      tipsDialogVisible: false,
      status: "",
      newFileList: [],
      count: 0,
      markUpload: true,
    };
  },
  computed: {
    dragOptions: function () {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      };
    },
    getStatusClass: function () {
      return function (value) {
        let statusObj = {
          1: "gray-text",
          2: "yellow-text",
          3: "blue-text",
          4: "black-text",
        };
        return statusObj[value];
      };
    },
  },
  filters: {
    formateStatus: function (value) {
      let statusObj = {
        1: "待定",
        2: "未开始",
        3: "进行中",
        4: "已完成",
      };
      return statusObj[value];
    },
  },
  inject: ["rightShow"],
  methods: {
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    singleOperation: function (val, item) {
      if (val !== 3) {
        let ajax = {};
        let url = "";
        ajax["id"] = item;
        if (val == 1) {
          //完成
          url = "/home/CrmTask/finishTask";
        } else if (val == 2) {
          //开始
          if (item.task_status && item.task_status != 2) {
            this.$message(toMessage("error", this.$t("taskInfo.text13")));
            return;
          }
          if (item.charge_user_ids && item.charge_user_ids == "") {
            this.$message(toMessage("error", this.$t("taskInfo.error")));
            return;
          }
          url = "/home/CrmTask/startTask";
        } else if (val == 4) {
          //复制
          url = "/home/CrmTask/copyTask";
        } else if (val == 5) {
          //激活
          url = "/home/CrmTask/activeTask";
        } else if (val == 6) {
          //还原
          url = "/home/CrmTask/restoreTask";
        } else if (val == 7) {
          url = "/home/CrmTask/deleteTask";
        }
        this.$confirm(
          this.$t("taskManagement.confirmToApply"),
          this.$t("tips"),
          {
            confirmButtonText: this.$t("confirm"),
            cancelButtonText: this.$t("cancel"),
            type: "warning",
          }
        ).then(() => {
          this.loading = false;
          this.axios
            .post(url, ajax)
            .then((res) => {
              this.loading = false;
              if (res.data.status !== 1) {
                this.$message(toMessage("error", res.data.msg));
                return;
              }
              this.$message(toMessage());
              this.addTab(
                "CrmTask",
                "taskManagement",
                "<tags:lang>任务列表</tags:lang>"
              );
            })
            .catch(() => {});
        });
      }
      if (val === 3) {
        //添加子任务
        this.selectTaskArray = [];
        this.setSinglVisible = true;
        this.subTaskShow = true;
        this.setSinglId = item;
        this.axios
          .post("/home/crmTask/getOtherTaskList", {
            id: item,
          })
          .then((res) => {
            if (res.data.status !== 1) {
              toMessage("error", res.data.msg);
              return;
            }
          })
          .catch(() => {});
      }
    },
    singlApplication: function (val) {
      if (val == 5) {
        //添加子任务
        let ajaxData = {};
        ajaxData["id"] = this.setSinglId;
        ajaxData["operation"] = 8;
        ajaxData["selectTaskArray"] = this.selectTaskArray;
        this.loading = false;
        this.axios
          .post("/home/CrmTask/addSubTask", ajaxData)
          .then((res) => {
            this.loading = false;
            this.setSinglVisible = false;
            this.subTaskShow = false;
            this.checkList = [];
            this.selectTaskArray = [];
            this.setSinglId = "";
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.addTab(
              "CrmTask",
              "taskManagement",
              "<tags:lang>任务列表</tags:lang>"
            );
          })
          .catch(() => {});
        return;
      }
    },
    tipsConfirm: function () {
      this.addTab("CrmTask", "taskManagement");
    },
    getInfo: function () {
      this.loading = true;
      this.axios
        .post("/home/crmTask/getTaskInfo", {
          id: this.id,
          is_view: this.is_view,
          sel_admin: this.admin,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          //没有权限
          if (res.data.data.permission && res.data.data.permission < 1) {
            this.tipsDialogVisible = true;
            return;
          }
          this.allData = res.data.data;

          if (res.data.data.allUserOptions) {
            this.allUserOptions = res.data.data.allUserOptions;
          }
          if (res.data.data.projectOptions) {
            this.projectOptions = res.data.data.projectOptions;
          }
          // if (res.data.tagsGroup) {
          //     this.addTags(res.data.tagsGroup)
          // }

          if (res.data.data.tags) {
            if (this.is_view == 1) {
              this.form.tags = res.data.data.tags;
            } else {
              //赋值标签
              //this.changeAccTags(res.data.tags)
              this.changeTagSort(res.data.data.tagsGroup, res.data.data.tags);
            }
          } else {
            if (res.data.data.tagsGroup) {
              this.changeTagSort(res.data.data.tagsGroup, res.data.data.tags);
            }
          }

          if (res.data.data.userGroup) {
            this.userGroup = res.data.data.userGroup;
          }
          if (res.data.data.chargeUserGroup) {
            this.chargeUserGroup = res.data.data.chargeUserGroup;
          }
          if (res.data.data.joinUserGroup) {
            this.joinUserGroup = res.data.data.joinUserGroup;
          }
          if (res.data.data.watchUserGroup) {
            this.watchUserGroup = res.data.data.watchUserGroup;
          }
          this.taskOptions = res.data.data.taskOptions;
          this.subTaskOptions = res.data.data.subTaskOptions;
          if (res.data.data && this.id > 0) {
            this.sourceId = res.data.data.source_id;
            if (res.data.data.source_type) {
              this.sourceType = res.data.data.source_type;
            }
            if (res.data.data.source_name) {
              this.sourceName = res.data.data.source_name;
            }
            if (res.data.data.source_other) {
              this.sourceOther = res.data.data.source_other;
            }

            this.attachments = res.data.data.attachments;
            if (this.attachments) {
              for (let j = 0; j < this.attachments.length; j++) {
                let file_name = this.attachments[j].name + "@";
                this.file_atta[file_name] = this.attachments[j].path;
              }
            }

            this.form.task_title = res.data.data.task_title;
            this.form.task_content = res.data.data.task_content;
            this.form.want_start_time = res.data.data.want_start_time;
            this.form.want_end_time = res.data.data.want_end_time;
            this.form.list_data = JSON.parse(
              JSON.stringify(res.data.data.listData)
            );
            (this.form.is_list = res.data.data.is_list == 1 ? true : false),
              (this.form.create_user_id = String(res.data.data.create_user_id));
            this.form.link_task_id =
              res.data.link_task_id > 0
                ? String(res.data.data.link_task_id)
                : "";
            this.form.project_id = res.data.data.project_id
              ? String(res.data.data.project_id)
              : "";
            this.beforeStorageValue = res.data.data.project_id
              ? String(res.data.data.project_id)
              : "";
            if (res.data.data.link_task_id > 0) {
              this.isShowLinkTask = true;
            }

            if (res.data.data.chargeGroup) {
              this.chargeGroup = res.data.data.chargeGroup;
            }
            if (res.data.data.joinGroup) {
              this.joinGroup = res.data.data.joinGroup;
            }
            if (res.data.data.watchGroup) {
              this.watchGroup = res.data.data.watchGroup;
            }
            this.form.sub_task_ids = [];
            if (res.data.data.subTaskIds) {
              res.data.data.subTaskIds.forEach((item) => {
                this.form.sub_task_ids.push(String(item));
              });
            }

            this.logData =
              res.data.data.logData === undefined ? [] : res.data.data.logData;
            this.handleChange();
          }

          let content = this.form.task_content ? this.form.task_content : "";
          setTimeout(() => {
            this.initEditor();
            if (this.editor) {
              this.setContent(content);
            }
          }, 10);
        })
        .catch(() => {});
    },
    cancelTag() {
      this.$confirm("确定取消吗", this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.addTab("CrmTask", "taskManagement");
        })
        .catch(() => {});
    },
    dissociated() {
      this.isShowLinkTask = false;
    },
    //获取callback的内容
    getData(data) {
      if (this.is_view != 0) {
        return;
      }

      if (Object.prototype.hasOwnProperty.call(data, "tagsGroup")) {
        this.iskeyShowLabel++;
        this.handleChange();
        //this.addTags(data.tagsGroup)
        let tags = [];
        if (this.form.tags) {
          this.form.tags.forEach((item) => {
            let obj = {};
            obj["name"] = item[1];
            tags.push(obj);
          });
        }
        this.changeTagSort(data.tagsGroup, tags);
      }
    },
    updateTags() {
      if (this.is_view != 0) {
        return;
      }
      this.loading = true;
      this.axios
        .post("/home/crmTask/getTaskInfo", {
          id: this.id,
          is_view: this.is_view,
          sel_admin: this.admin,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          if (res.data.tags) {
            this.changeTagSort(res.data.tagsGroup, res.data.tags);
          } else {
            if (res.data.tagsGroup) {
              this.changeTagSort(res.data.tagsGroup, res.data.tags);
            }
          }
        });
    },
    // 完成个数
    getFinishNum(index) {
      let n = 0;
      this.form.list_data[index].step_list.forEach((sItem) => {
        if (sItem.step_status == 4) {
          n += 1;
        }
      });
      return n;
    },
    // 完成百分比
    getPercentage(index) {
      let num = this.getFinishNum(index);
      return Math.round(
        (num / this.form.list_data[index].step_list.length).toFixed(2) * 100
      );
    },
    //获取列表
    cancel() {
      this.showItemId = "";
      this.showMainComment = true;
      this.inputComment = "";
      this.replyId = 0;
      this.replyToId = 0;
    },
    commitComment(mainId) {
      if (this.inputComment == "") {
        return;
      }
      let ajaxData = {
        task_id: this.id,
        last_send_id: this.replyToId,
        content: this.inputComment,
        main_c_id: mainId,
        p_c_id: this.replyId,
      };
      this.axios
        .post("/home/crmTask/taskCommentPut", ajaxData)
        .then((res) => {
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          this.getInfo();
          this.showMainComment = true;
          this.inputComment = "";
          this.replyId = 0;
          this.replyToId = 0;
        })
        .catch(() => {});
    },
    showCommentInput(item, reply = null) {
      this.showItemId = item.id;
      if (reply) {
        this.replyId = reply.id;
        this.replyToId = reply.to_id;
      } else {
        this.replyId = item.id;
        this.replyToId = item.from_id;
      }

      this.showMainComment = false;
    },
    //添加富文本内容
    addContent(text) {
      this.editor.cmd.do("insertHTML", text);
      this.editor.change();
    },
    //富文本内容赋值
    setContent(text) {
      this.editor.txt.html(text);
      this.editor.change();
    },
    assignUser(item, index) {
      this.$set(this.tipVisibles, index, false);
      if (item) {
        this.addContent(
          "<a  href='##' rel='@" +
            item.value +
            "' ref='@" +
            item.label +
            "' id='@" +
            item.photo +
            "'>@" +
            item.label +
            "</a>"
        );
      }
    },
    deleteTaskList(index) {
      this.$confirm(this.$t("taskInfo.text14"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.form.list_data.splice(index, 1);
          if (this.form.list_data.length == 0) {
            this.is_already_list == false;
          }
        })
        .catch(() => {});
    },
    addTaskList(addType = null) {
      let that = this;
      that.form.is_list = true;
      if (this.form.list_data.length >= 5) {
        this.$message(toMessage("error", this.$t("taskInfo.text15")));
        return;
      }
      let newStepData;
      let html = this.editor.txt.html();
      this.currentIndex = Number(this.form.list_data.length);
      let reg = /<p[^>]*>(?:(?!<\/p>)[\s\S])*<\/p>/gi;
      let paragraphs = html.match(reg);

      paragraphs = paragraphs.filter((item) => {
        return item !== "<p><br></p>";
      });

      let regUserAll = /<a href="##" rel="@(.*?)" ref="@(.*?)" id="@(.*?)">@(.*?)<\/a>/g;
      let regUser = /<a href="##" rel="@(.*?)" ref="@(.*?)" id="@(.*?)">@(.*?)<\/a>/;
      if (html != null && addType == "auto" && this.is_already_list == false) {
        if (paragraphs) {
          var i = 0;
          paragraphs.forEach(function (item) {
            let user = item.match(regUser);
            let step_at = 0;
            let user_name = "";
            let user_avatar = "";
            if (user) {
              item = item.replace(regUserAll, "");
              step_at = user[1] != "" ? user[1] : 0;
              user_name = user[2];
              user_avatar = user[3];
            } else {
              step_at = 0;
              user_name = "";
              user_avatar = "";
            }
            item = that.escape2Html(item.replace(/<[^>]*>/gi, ""));
            item = item.replace(/\n[\n]+/g, "");
            if (item) {
              that.$set(that.stepData.step_list, i, {
                label: item.trim(),
                step_at: step_at,
                step_status: 2,
                step_index: 0,
                user_name: user_name,
                user_avatar: user_avatar,
              });
              i++;
            }
          });
        }
        this.is_already_list = true;
        this.form.list_data.splice(this.currentIndex, 1, that.stepData);
      } else {
        newStepData = {
          list_name: "",
          step_list: [
            {
              label: "",
              step_at: 0,
              step_status: 2,
              step_index: 0,
              user_name: "",
              user_avatar: "",
            },
          ],
        };
        if (addType == "auto") {
          if (paragraphs) {
            var j = 0;
            paragraphs.forEach(function (item) {
              let user = item.match(regUser);
              let step_at = 0;
              let user_name = "";
              let user_avatar = "";
              if (user) {
                item = item.replace(regUserAll, "");
                step_at = user[1] != "" ? user[1] : 0;
                user_name = user[2];
                user_avatar = user[3];
              }
              item = that.escape2Html(item.replace(/<[^>]*>/gi, ""));
              item = item.replace(/\n[\n]+/g, "");
              if (item) {
                that.$set(newStepData.step_list, j, {
                  label: item.trim(),
                  step_at: step_at,
                  step_status: 2,
                  step_index: 0,
                  user_name: user_name,
                  user_avatar: user_avatar,
                });
                j++;
              }
            });
          }
        }

        this.form.list_data.splice(this.currentIndex, 1, newStepData);
      }
    },
    //转意符换成普通字符
    escape2Html(str) {
      var arrEntities = {
        lt: "<",
        gt: ">",
        nbsp: " ",
        amp: "&",
        quot: '"',
      };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    isAlreadyList(event) {
      if (event != null) {
        this.is_already_list = true;
      }
    },
    getStepNum(num) {
      let numNew = num + 1;
      let rowLen = plus(divide(numNew, 10), 1);
      return rowLen;
    },
    assignIndexRow(val, index, rows) {
      if (val >= 0) {
        let obj = rows[index];
        this.$set(obj, "step_index", val);
      }
    },
    assignStatusRow(val, index, rows) {
      let obj = rows[index];
      this.$set(obj, "step_status", val);
    },
    // 步骤指派人员
    assignUserRow(index, stepIndex, val) {
      this.$set(
        this.form.list_data[index].step_list[stepIndex],
        "step_at",
        val.id
      );
      this.$set(
        this.form.list_data[index].step_list[stepIndex],
        "user_name",
        val.name
      );
      this.$set(
        this.form.list_data[index].step_list[stepIndex],
        "user_avatar",
        val.photo
      );
    },
    // 取消指派
    cancelAssign(index, stepIndex) {
      if (this.form.list_data[index].step_list[stepIndex].step_at) {
        this.$set(
          this.form.list_data[index].step_list[stepIndex],
          "step_at",
          0
        );
        this.$set(
          this.form.list_data[index].step_list[stepIndex],
          "user_name",
          ""
        );
        this.$set(
          this.form.list_data[index].step_list[stepIndex],
          "user_avatar",
          ""
        );
        document.getElementById("task-info").click();
      }
    },
    deleteRow(index, stepIndex) {
      this.form.list_data[index].step_list.splice(stepIndex, 1);
      // rows.splice(index, 1)
      // for (var i = 0; i < this.form.list_data.length; i++) {
      //     if (this.form.list_data[i].step_list.length === 0) {
      //         this.form.list_data.splice(i, 1);
      //     }
      // }
    },
    addRow(index) {
      this.form.list_data[index].step_list.push({
        label: "",
        step_at: 0,
        step_status: 2,
        step_index: 0,
      });
    },
    //查看 type1任务；2项目
    view(id, type) {
      if (type == 1) {
        this.addTab("CrmTask", "taskInfo", "<tags:lang>任务详情</tags:lang>", {
          id: id,
          is_view: 1,
        });
        console.log(id);
      } else if (type == 2) {
        this.addTab(
          "CrmTask",
          "projectInfo",
          "<tags:lang>项目详情</tags:lang>",
          {
            id: id,
            is_view: 1,
          }
        );
      }
    },
    // 获取所有用户
    getAllUserList() {
      this.axios
        .post("/home/crmTask/getUserList", {
          id: this.id,
        })
        .then((res) => {
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          this.allUserOptions = res.data.data;
        })
        .catch(() => {});
    },
    handleChange() {
      let that = this;
      setTimeout(function () {
        let a = that.$refs["myCascader"];
        if (a != null) {
          let c = that.$refs["myCascader"].$el;
          var cl = c.getElementsByClassName("el-cascader__tags");
          if (cl.length > 0) {
            var d = cl[0].getElementsByClassName("el-tag--info");
            for (let i = 0; i < d.length; i++) {
              let m =
                that.returnColor(that.allTags[d[i].textContent]["value"]) +
                " el-tag--info el-tag--small el-tag--light";
              d[i].className = m;
            }
          }
        }
      }, 500);
    },
    //改变标签的形式
    changeAccTags(data) {
      this.hasTags = [];
      this.form.tags = [];
      data.forEach((item) => {
        let obj = [];
        if (item["value"] == "#0A84FF") {
          //蓝色
          obj.push(1);
        }
        if (item["value"] == "#FF575C") {
          //红色
          obj.push(2);
        }
        if (item["value"] == "#F7BE16") {
          //黄色
          obj.push(3);
        }
        if (item["value"] == "#FF7315") {
          //橙色
          obj.push(4);
        }
        if (item["value"] == "#0ACE0D") {
          //绿色
          obj.push(5);
        }
        obj.push(item.name);
        this.form.tags.push(obj)[item["name"]] = item;
      });
    },
    //添加标签
    addTags(data) {
      //this.tagsGroup = data
      this.tagsGroup[0]["children"] = [];
      this.tagsGroup[1]["children"] = [];
      this.tagsGroup[2]["children"] = [];
      this.tagsGroup[3]["children"] = [];
      this.tagsGroup[4]["children"] = [];
      this.allTags = {};
      data.forEach((item) => {
        let obj = [];
        obj["label"] = item["name"];
        obj["value"] = item["id"];
        obj["color"] = item["value"];
        if (item["value"] == "#0A84FF") {
          //蓝色
          this.tagsGroup[0]["children"].push(obj);
        }
        if (item["value"] == "#FF575C") {
          //红色
          this.tagsGroup[1]["children"].push(obj);
        }
        if (item["value"] == "#F7BE16") {
          //黄色
          this.tagsGroup[2]["children"].push(obj);
        }
        if (item["value"] == "#FF7315") {
          //橙色
          this.tagsGroup[3]["children"].push(obj);
        }
        if (item["value"] == "#0ACE0D") {
          //绿色
          this.tagsGroup[4]["children"].push(obj);
        }
        this.allTags[item["name"]] = item;
      });
    },
    //重组标签的顺序 data 所有标签 tags选择的标签
    changeTagSort(data, tags) {
      this.tagsGroup[0]["children"] = [];
      this.tagsGroup[1]["children"] = [];
      this.tagsGroup[2]["children"] = [];
      this.tagsGroup[3]["children"] = [];
      this.tagsGroup[4]["children"] = [];
      this.allTags = {};
      data.forEach((item) => {
        let obj = [];
        obj["label"] = item["name"];
        obj["value"] = item["id"];
        obj["color"] = item["value"];
        if (item["value"] == "#0A84FF") {
          //蓝色
          this.tagsGroup[0]["children"].push(obj);
        }
        if (item["value"] == "#FF575C") {
          //红色
          this.tagsGroup[1]["children"].push(obj);
        }
        if (item["value"] == "#F7BE16") {
          //黄色
          this.tagsGroup[2]["children"].push(obj);
        }
        if (item["value"] == "#FF7315") {
          //橙色
          this.tagsGroup[3]["children"].push(obj);
        }
        if (item["value"] == "#0ACE0D") {
          //绿色
          this.tagsGroup[4]["children"].push(obj);
        }
        this.allTags[item["name"]] = item;
      });
      if (tags == undefined || tags == "" || tags.length == 0) {
        return;
      }
      let i = 0;
      let keyTags = {};
      this.tagsGroup.forEach((item, index) => {
        item["children"].forEach((it) => {
          let obj = [];
          obj.push(index + 1);
          obj.push(it["value"]);
          obj.push(i);
          obj.push(it);
          i++;
          keyTags[it["value"]] = obj;
        });
      });
      let sortTags = [];
      this.hasTags = [];
      this.form.tags = [];

      tags.forEach((item) => {
        let obj = [];
        obj.push(keyTags[item["name"]][0]);
        obj.push(keyTags[item["name"]][1]);
        sortTags[keyTags[item["name"]][2]] = obj;
        this.hasTags[item["name"]] = item;
      });
      this.form.tags = sortTags.filter(function (s) {
        return s;
      });
    },
    returnColor(color) {
      let select = {
        "#0A84FF": "only-el-color-tags-one",
        "#FF575C": "only-el-color-tags-two",
        "#F7BE16": "only-el-color-tags-three",
        "#FF7315": "only-el-color-tags-four",
        "#0ACE0D": "only-el-color-tags-five",
      };
      return select[color];
    },
    handleExpandChange() {
      this.$nextTick(function () {
        let cc = this.$refs["myCascader"].popperJS;
        if (cc != null) {
          let c = cc._popper;
          let b = c.getElementsByClassName("el-cascader-panel");
          let d = b[0].childNodes;

          //增加按钮
          if (this.rightShow("CrmTask@getTaskLabel")) {
            let left = d[0];
            if (left != null) {
              // let e = left.getElementsByClassName("el-scrollbar__view");
              // let child = e[0];
              // let childLength = child.getElementsByClassName(
              //   "el-cascader-node"
              // );
              // if (childLength.length <= 5) {
              //   child.innerHTML =
              //     child +
              //     `<li role="menuitem" id="cascader-menu-7218-0-3" tabindex="-1" class="el-cascader-node" aria-haspopup="true" aria-owns="cascader-menu-7218-0"><label class="el-checkbox"><span class="el-checkbox__input button-icon-color"><span class="el-icon-plus"></span><input type="checkbox" aria-hidden="false" class="el-checkbox__original" value=""></span></label><span class="el-cascader-node__label"><div type="button" class="button-add-tags" onclick="vue.fn()">` +
              //     this.$t("projectInfo.newTag") +
              //     `</div></span></li>`;
              // }
            }
          }
          //改变颜色
          let f = d[1];
          if (f != null) {
            f.style.minWidth = "220px";
            let e = f.getElementsByClassName("el-cascader-node");
            for (let i = 0; i < e.length; i++) {
              e[i].style.height = "40px";
              let objLeft = e[i].getElementsByTagName("label");
              objLeft[0].style.float = "right";
              objLeft[0].style.marginLeft = "70px";
              objLeft[0].style.bottom = "3px";
              e[i].append(objLeft[0]);

              let obj = e[i].getElementsByTagName("span");
              obj[0].style.backgroundColor = this.allTags[obj[0].innerHTML][
                "value"
              ];
              obj[0].style.color = "#fff";
              obj[0].style.maxWidth = "500px";
              obj[0].style.maxHeight = "28px";
              obj[0].style.textAlign = "center";
              obj[0].style.lineHeight = "28px";
              obj[0].style.marginBottom = "3px";
              obj[0].title = e[i].innerHTML;
            }
          }
        }
      });
    },
    // 编辑
    editTask(id) {
      this.addTab(
        "CrmTask",
        "taskInfo",
        "<tags:lang>任务</tags:lang>-<tags:lang>edit</tags:lang>",
        {
          id: id,
        }
      );
    },
    // 完成
    finishTask(id) {
      this.$confirm(this.$t("taskInfo.text16"), this.$t("tips"), {
        confirmButtonText: this.$t("continue"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        this.axios
          .post("/home/CrmTask/finishTask", { id })
          .then((res) => {
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.addTab(
              "CrmTask",
              "taskManagement",
              "<tags:lang>任务列表</tags:lang>"
            );
          })
          .catch(() => {});
      });
    },
    //1查看  0编辑  2删除
    viewOther(id, sourceType, sourceOther) {
      if (sourceType == 1) {
        this.addTab(
          "CrmContact",
          "contactEdit",
          "<tags:lang>客户详情</tags:lang>",
          {
            id: id,
            is_view: 1,
          }
        );
      }
      if (sourceType == 2) {
        this.addTab(
          "CrmContact",
          "clueDetails",
          "<tags:lang>线索详情</tags:lang>",
          {
            id: id,
            is_view: 1,
          }
        );
      }
      if (sourceType == 3) {
        this.addTab(
          "Sale",
          "manualSales",
          "<tags:lang>销售单</tags:lang>-<tags:lang>详情</tags:lang>",
          { id: id, is_confirm: 1 }
        );
      }
      if (sourceType == 4) {
        this.addTab(
          "Sale",
          "OrderSaleOutInfo",
          "<tags:lang>CK</tags:lang>-<tags:lang>详情</tags:lang>",
          { id: id, is_view: 1 }
        );
      }
      if (sourceType == 5) {
        this.addTab(
          "Pur",
          "purchaseReach",
          "<tags:lang>入库单</tags:lang>-<tags:lang>详情</tags:lang>",
          { id: id }
        );
      }
      if (sourceType == 6) {
        this.addTab(
          "warehouse",
          "orderLockInfo",
          "<tags:lang>锁库单</tags:lang>-<tags:lang>详情</tags:lang>",
          { id: id, isView: 1 }
        );
      }
      if (sourceType == 7) {
        this.addTab(
          "Pur",
          "composeAgent",
          "<tags:lang>跨国物流</tags:lang>-<tags:lang>详情</tags:lang>",
          { id: id, is_read: 1 }
        );
      }
      if (sourceType == 8) {
        let sourceTitle;
        let isRecord;
        if (sourceOther == 1) {
          isRecord = 1;
          sourceTitle =
            "<tags:lang>备案单</tags:lang>-<tags:lang>详情</tags:lang>";
        } else {
          sourceTitle =
            "<tags:lang>price</tags:lang>-<tags:lang>详情</tags:lang>";
          isRecord = 0;
        }
        this.addTab("Sale", "quoteSheetInfo", sourceTitle, {
          id: id,
          is_view: 1,
          is_record: isRecord,
        });
      }
      if (sourceType == 9) {
        this.addTab(
          "Pur",
          "purInfo",
          "<tags:lang>采购订单</tags:lang>-<tags:lang>详情</tags:lang>",
          { id: id, is_view: 1 }
        );
      }
    },
    getSubmitUser() {
      var chargeUser = this.getUserIdArray(this.chargeGroup);
      var joinUser = this.getUserIdArray(this.joinGroup);
      var watchUser = this.getUserIdArray(this.watchGroup);
      var tagsArray = [];
      if (this.form.tags) {
        this.form.tags.forEach((item) => {
          if (Object.prototype.hasOwnProperty.call(this.hasTags, item[1])) {
            tagsArray.push(this.hasTags[item[1]]["name"]);
          } else {
            tagsArray.push(item[1]);
          }
        });
      }
      return {
        chargeUser,
        joinUser,
        watchUser,
        tagsArray,
      };
    },
    changeProject(val) {
      if (this.beforeStorageValue) {
        if (val != this.beforeStorageValue) {
          this.$confirm(this.$t("taskInfo.text17"), this.$t("tips"), {
            confirmButtonText: this.$t("continue"),
            cancelButtonText: this.$t("cancel"),
            type: "warning",
          })
            .then(() => {
              this.$refs["projectSelect"].blur();
            })
            .catch(() => {
              this.form.project_id = this.beforeStorageValue;
              this.$refs["projectSelect"].blur();
            });
        }
      }
    },
    //提交
    submit(status, type) {
      this.status = status;
      // 检测必填项
      if (this.form.task_title == "") {
        this.$message(toMessage("error", this.$t("taskInfo.text18")));
        return;
      }
      if (this.form.task_content == "") {
        this.$message(toMessage("error", this.$t("taskInfo.text19")));
        return;
      }
      if (this.sourceType == 2 && this.form.want_start_time == "") {
        this.$message(toMessage("error", this.$t("taskInfo.text20")));
        return;
      }

      if (this.form.want_start_time != "" && this.form.want_end_time != "") {
        if (
          new Date(this.form.want_end_time).getTime() <=
          new Date(this.form.want_start_time).getTime()
        ) {
          this.$message(toMessage("error", this.$t("taskInfo.text21")));
          return;
        }
      }

      for (var i = 0; i < this.form.list_data.length; i++) {
        if (this.form.list_data[i].list_name == "") {
          this.$message(toMessage("error", this.$t("taskInfo.text22")));
          return;
        }
      }

      let chargeUser = this.getSubmitUser().chargeUser;
      let joinUser = this.getSubmitUser().joinUser;
      let watchUser = this.getSubmitUser().watchUser;
      let tagsArray = this.getSubmitUser().tagsArray;

      //判断权限
      if (this.allData.permission < 3) {
        let chargeUserArray = this.allData.charge_user_ids
          ? this.allData.charge_user_ids.split(",")
          : [];
        if (
          chargeUserArray.length > 0 &&
          !this.includeArray(chargeUser, chargeUserArray)
        ) {
          this.$message(toMessage("error", this.$t("taskInfo.text23")));
          return;
        }
      }

      if (this.allData.permission < 2) {
        let joinUserArray = this.allData.join_user_ids
          ? this.allData.join_user_ids.split(",")
          : [];
        if (
          joinUserArray.length > 0 &&
          !this.includeArray(joinUser, joinUserArray)
        ) {
          this.$message(toMessage("error", this.$t("taskInfo.text24")));
          return;
        }
      }

      if (this.allData.permission <= 1 || this.allData.permission == "") {
        let watchUserArray = this.allData.watch_user_ids
          ? this.allData.watch_user_ids.split(",")
          : [];
        if (
          watchUserArray.length > 0 &&
          !this.includeArray(watchUser, watchUserArray)
        ) {
          this.$message(toMessage("error", this.$t("taskInfo.text25")));
          return;
        }
      }

      if (status == 3 && type == "go") {
        if (chargeUser.length == 0) {
          this.$message(toMessage("error", this.$t("taskInfo.text26")));
          return;
        }
      }

      if (this.isTheSame(chargeUser, joinUser)) {
        this.$message(toMessage("error", this.$t("taskInfo.text27")));
        return;
      }
      if (this.isTheSame(chargeUser, watchUser)) {
        this.$message(toMessage("error", this.$t("taskInfo.text28")));
        return;
      }
      if (this.isTheSame(joinUser, watchUser)) {
        this.$message(toMessage("error", this.$t("taskInfo.text29")));
        return;
      }
      // if (this.sourceId > 0) {
      //     let title = this.form.task_title
      //     let content = this.form.task_content
      //     if (['1', '2'].indexOf(this.sourceType) >= 0) {
      //         this.form.task_title = 'CRM:' + title
      //         //客户
      //         if (this.sourceType == 1)
      //             this.form.task_content = "<tags:lang>从客户创建的任务</tags:lang>" + ':' + content
      //         //线索
      //         if (this.sourceType == 2)
      //             this.form.task_content = "<tags:lang>从线索创建的任务</tags:lang>" + ':' + content
      //     } else if (['3', '4', '5', '6', '7', '8', '9'].indexOf(this.sourceType) >= 0) {
      //         this.form.task_title = 'ERP:' + title
      //         this.form.task_content = "<tags:lang>从ERP创建的任务</tags:lang>" + ':' + content
      //     }
      // }
      if (this.form.path && this.form.path.length > 0) {
        this.upload();
        return;
      }

      this.submitAjax(status, chargeUser, joinUser, watchUser, tagsArray);
    },
    //下载附件
    downloadAtt(item) {
      this.$confirm(this.$t("taskInfo.confirmToDownload"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          Post("/home/CrmTask/downloadAttNow", [
            { name: "path", value: item.path },
            { name: "task_id", value: this.id },
            { name: "name", value: item.name },
          ]);
        })
        .catch(() => {});
    },
    // 删除附件
    delAttach(index) {
      delete this.file_atta[this.attachments[index].name + "@"];
      this.attachments.splice(index, 1);
    },
    //上传附件
    upload() {
      this.$refs.upload.submit();
    },
    //上传框 状态发生改变时
    uploadOnchange(file, fileList) {
      this.newFileList = fileList;
      var uploadFileLen = fileList ? fileList.length : 0;
      var oldFilLen = this.attachments ? this.attachments.length : 0;
      var acceptLen = 5 - oldFilLen;
      //判断文件上传超过5个
      if (uploadFileLen + oldFilLen > 5) {
        if (this.markUpload) {
          this.$message(toMessage("warning", this.$t("taskInfo.error1")));
        }
        if (acceptLen > 0) {
          console.log(acceptLen);
          this.form.path = this.form.path.filter(function (element, index) {
            return index < acceptLen;
          });
        } else {
          this.form.path = [];
        }
        this.markUpload = false;
        return;
      }
      // 判断图片是否过大
      if (file.size > this.size * 1024) {
        this.$message(toMessage("warning", this.$t("taskInfo.error2")));
        return;
      }
      // 判断图片是否符合格式
      let lastName = "." + file.name.replace(/.+\./, "").toLowerCase();
      if (this.uploadAccept.indexOf(lastName) < 0) {
        this.$message(
          toMessage("warning", file.name + this.$t("taskInfo.error3"))
        );
        return;
      }
      let newFileList = [];
      for (let i = 0; i < fileList.length; i++) {
        let have = true;
        // 判断是否重复
        for (let j = 0; j < newFileList.length; j++) {
          if (fileList[i].name == newFileList[j].name) {
            have = false;
            break;
          }
        }
        // 判断图片是否过大
        if (fileList[i].size > this.size * 1024) {
          have = false;
        }
        // 判断图片是否符合格式
        if (
          this.uploadAccept.indexOf(
            "." + fileList[i].name.replace(/.+\./, "").toLowerCase()
          ) < 0
        ) {
          have = false;
        }
        if (have) {
          newFileList.push(fileList[i]);
        }
      }
      this.form.path = newFileList;
    },
    //文件上传超限制
    exceed() {
      this.$message(toMessage("warning", this.$t("taskInfo.error1")));
      return;
    },
    //上传成功回调
    successUpload(res, file) {
      if (res.status != 1) {
        file.status = "ready";
        this.$message(
          toMessage("error", file.name + this.$t("taskInfo.error4"))
        );
      }
      this.file_atta[res.data.name] = res.data.path;

      this.count++;
      if (this.form.path.length === this.count) {
        let chargeUser = this.getSubmitUser().chargeUser;
        let joinUser = this.getSubmitUser().joinUser;
        let watchUser = this.getSubmitUser().watchUser;
        let tagsArray = this.getSubmitUser().tagsArray;
        this.count = 0;
        this.submitAjax(
          this.status,
          chargeUser,
          joinUser,
          watchUser,
          tagsArray
        );
      }
    },
    submitAjax(status, chargeUser, joinUser, watchUser, tagsArray) {
      this.loading = false;
      this.sourceName =
        this.sourceName != "" && this.sourceName != undefined
          ? this.sourceName
          : "";
      this.sourceOther =
        this.sourceOther != "" && this.sourceOther != undefined
          ? this.sourceOther
          : "";
      this.axios
        .post("/home/crmTask/taskInfoPut", {
          id: this.id,
          task_title: this.form.task_title.trim(),
          task_content: this.form.task_content.trim(),
          task_status: status,
          path: this.file_atta,
          list_data: this.form.list_data,
          is_list: this.form.is_list ? 1 : 0,
          want_start_time: this.form.want_start_time
            ? this.form.want_start_time
            : "",
          want_end_time: this.form.want_end_time ? this.form.want_end_time : "",
          create_user_id: this.form.create_user_id,
          charge_user_ids: chargeUser.join(","),
          join_user_ids: joinUser.join(","),
          watch_user_ids: watchUser.join(","),
          project_id: this.form.project_id,
          task_tags: tagsArray ? tagsArray.join(",") : "",
          pid: this.form.pid ? this.form.pid : 0,
          sub_task_ids: this.form.sub_task_ids ? this.form.sub_task_ids : [],
          link_task_id: this.form.link_task_id,
          source_id: this.sourceId,
          source_type:
            this.sourceType +
            "|@|" +
            this.sourceName +
            "|@|" +
            this.sourceOther,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status != 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          this.$message(toMessage());

          this.addTab("CrmTask", "taskManagement");
        })
        .catch(() => {});
    },
    // 删除文件
    RemoveFile(val) {
      for (let i = 0; i < this.form.path.length; i++) {
        if (val.uid == this.form.path[i].uid) {
          this.form.path.splice(i, 1);
        }
      }
      let name = val.name + "@";
      if (Object.prototype.hasOwnProperty.call(this.file_atta, name)) {
        delete this.file_atta[name];
      }
    },
    // 改变步骤状态
    changeStatus(index, stepIndex) {
      if (this.id > 0 && this.is_view > 0) {
        let nowStatus =
          this.form.list_data[index].step_list[stepIndex].step_status * 1;
        let nowStepAt =
          this.form.list_data[index].step_list[stepIndex].step_at * 1;
        let nowData = this.form.list_data[index].step_list[stepIndex];
        if (nowStatus == 4) {
          nowData.step_status = "2";
          this.form.list_data[index].step_list.splice(stepIndex, 1, nowData);
        } else {
          nowData.step_status = "4";
          this.form.list_data[index].step_list.splice(stepIndex, 1, nowData);
        }
        let ajax = {
          task_id: this.id,
          list_data: [this.form.list_data[index]],
          index_array: [index, stepIndex],
          step_status: nowStatus,
          step_at: nowStepAt,
        };

        this.axios
          .post("/home/crmTask/changeListStatus", ajax)
          .then((res) => {
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              this.getInfo();
              return;
            }
            this.$message(toMessage());
          })
          .catch(() => {});
      } else {
        let nowStatus =
          this.form.list_data[index].step_list[stepIndex].step_status * 1;
        let nowData = this.form.list_data[index].step_list[stepIndex];
        if (nowStatus == 4) {
          nowData.step_status = "2";
          this.form.list_data[index].step_list.splice(stepIndex, 1, nowData);
        } else {
          nowData.step_status = "4";
          this.form.list_data[index].step_list.splice(stepIndex, 1, nowData);
        }
      }
    },
    fn() {
      this.is_open_window_crm_cus = !this.is_open_window_crm_cus;
      this.iskeyShowLabel++;
      this.handleChange();
    },
    initEditor: function () {
      // var E = window.wangEditor;
      const editor = new E("#editor");
      this.editor = editor;
      this.editor.config.zIndex = 2;
      this.editor.config.pasteFilterStyle = true;
      // 或者 var editor = new E( document.getElementById('editor') )
      const vm = this;
      this.editor.config.onchange = function (html) {
        vm.form.task_content = html;
        let reg = /<a href="##" rel="@(.*?)" ref="@(.*?)" id="@(.*?)">@(.*?)<\/a>/g;
        let paragraphs = html.match(reg);
        if (paragraphs) {
          paragraphs.forEach((item) => {
            var itemArr = item.match(
              /<a href="##" rel="@(.*?)" ref="@(.*?)" id="@(.*?)">@(.*?)<\/a>/
            );
            if (itemArr[2] != itemArr[4]) {
              let id = itemArr[1];
              var re = new RegExp(
                '<a href="##" rel="@' +
                  id +
                  '" ref="@(.*?)" id="@(.*?)">@(.*?)</a>',
                "gim"
              );
              let res = html.replace(re, "");
              vm.setContent(res);
            }
          });
        }
      };
      this.editor.config.menus = [
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      // 自定义字体
      this.editor.config.fontNames = ["宋体", "微软雅黑"];
      // 多语言
      this.editor.config.lang = {
        设置标题: "title",
        正文: "p",
        链接文字: "link text",
        上传图片: "upload image",
        上传: "upload",
        创建: "init",
        字体: "fonts",
        文字颜色: "text color",
        背景色: "background color",
        设置列表: "settings list",
        对齐方式: "alignment",
        网络图片: "web picture",
        图片链接: "image link",
        链接: "link",
        插入表格: "insert table",
        插入: "insert",
        字号: "font size",
        有序列表: "ordered list",
        无序列表: "unordered list",
        行: "line",
        列: "column",
        靠左: "  left",
        居中: "center",
        靠右: " right",
        的表格: " ",
      };
      this.editor.create();
      this.editor.txt.html("");
    },
  },
  beforeDestroy() {
    // 销毁编辑器
    this.editor.destroy();
    this.editor = null;
  },
  created: function () {
    let project = this.$route.params;
    this.taskStatus = Number(project.status) || 0;
    this.is_view = Number(project.is_view) || 0;
    this.id = project.id || "";
    this.status = project.status || "";
    this.getInfo();
  },
  updated: function () {
    let that = this;
    let a = that.$refs["myCascader"];
    if (a != null) {
      let c = that.$refs["myCascader"].$el;
      var cl = c.getElementsByClassName("el-cascader__tags");
      if (cl.length > 0) {
        var d = cl[0].getElementsByClassName("el-tag--info");
        for (let i = 0; i < d.length; i++) {
          let m =
            that.returnColor(that.allTags[d[i].textContent]["value"]) +
            " el-tag--info el-tag--small el-tag--light";
          d[i].className = m;
        }
      }
    }
  },
  watch: {
    newFileList: function () {
      this.markUpload = true;
    },
    $route: function (to, from) {
      if (to !== from) {
        location.reload();
      }
    },
    is_open_window_crm_cus: function () {
      if (this.is_open_window_crm_cus == false) {
        this.updateTags();
      }
    },
  },
};
</script>

<style scoped>
#task-info {
  padding: 28px !important;
  color: #1c1c1e;
}

.container {
  padding-bottom: 80px;
}

.titH {
  color: #606266;
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 6000;
}

.tit {
  color: #606266;
  min-width: 110px;
}

#editor {
  width: 725px;
}

.only-el-color-tags {
  background: red;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}

.only-el-color-tags-one {
  background: #0a84ff;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}

.only-el-color-tags-two {
  background: #ff575c;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}

.only-el-color-tags-three {
  background: #f7be16;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}

.only-el-color-tags-four {
  background: #ff7315;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}

.only-el-color-tags-five {
  background: #0ace0d;
  border-color: #e9e9eb;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  white-space: nowrap;
  margin: 0 4px 3px;
}

.el-tag--small {
  color: #fff;
}

.handel-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #fff;
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.handel-box >>> .el-button {
  min-width: 120px;
}

.handel-box >>> .el-button:not(:last-child) {
  margin-right: 88px;
}

.edit-Box {
  margin-top: 16px;
  margin-left: 120px;
  margin-bottom: 18px;
  color: #858585;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: baseline;
}

.edit-Box i {
  font-size: 18px;
}

.add-task_box {
  width: 300px;
  margin-top: 66px;
}

.task-item {
  color: #4a4a4a;
  margin-bottom: 26px;
}

.mr24 {
  margin-right: 24px;
}

.el-select .el-tag__close.el-icon-close {
  background-color: transparent;
}

.dropdown-span {
  font-size: 14px;
  color: #858585;
}

.w268 {
  width: 268px;
}
.el-icon-close-tip {
  display: none !important;
}
.list-item {
  width: 825px;
  color: #4a4a4a;
  font-size: 14px;
  padding: 10px 0;
  border: 1px dashed transparent;
  margin-bottom: 10px;
  cursor: move;
}

.list-item:hover {
  border: 1px dashed #aaa;
  background-color: #fff;
}

.no-hover {
  cursor: auto;
}

.no-hover:hover {
  border: 1px dashed transparent;
}

.step-box {
  padding: 14px 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.mb16 {
  margin-bottom: 16px;
}

.add-step_btn {
  padding: 5px 0;
  color: #ff7315;
  margin-top: 10px;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
}

.comment-container {
  box-sizing: border-box;
  width: 550px;
}

.comment {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #0000001a;
}

.info {
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.time {
  color: #1c1c1e;
  opacity: 0.6;
}

.user-name {
  color: #1c1c1e;
  opacity: 0.8;
}

.content {
  color: #1c1c1e;
  line-height: 1.5;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 30px;
  width: 90%;
}

.replay-item {
  margin-bottom: 18px;
}

.btn-control {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.direction-c {
  display: flex;
  flex-direction: column;
}

.user-avatar {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.assign-user_name {
  color: #1c1c1e;
}

.log-container {
  position: relative;
  width: 550px;
  margin-top: 78px;
}

.tit-top {
  margin-bottom: 16px;
}

.log-tit {
  font-size: 18px;
  font-weight: 600;
  color: #505050;
}

.log-item {
  margin-bottom: 16px;
  display: flex;
  color: #4a4a4a;
  line-height: 1.5;
}

.log-time {
  opacity: 0.6;
  margin-right: 12px;
}

.log-info {
  max-width: 350px;
}

.tag-span {
  margin-left: 10px;
  color: #fff;
  padding: 4px 5px;
  display: inline-block;
  border-radius: 3px;
}

.list_tit {
  color: #1c1c1e;
  font-size: 14px;
}

.progress {
  width: 164px;
  margin-left: 21px;
}

.progress >>> .el-progress-bar__inner {
  background: #ff7315;
}

.assign-popover {
  display: flex;
  flex-direction: column;
  padding: 2px;
}

.assign-popover span {
  color: #333333;
  padding: 6px 10px;
  cursor: pointer;
}

.assign-popover span:hover {
  color: #ff7315;
}

.isCacheIcon {
  color: #15c051;
}

.noCacheIcon {
  color: #666666;
}

.download-icon {
  cursor: pointer;
}

/* 圆点 */
.active-dot {
  color: #ff7315;
}

.user-op_box {
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.step-item {
  align-items: center;
  margin-bottom: 5px;
  padding: 5px 10px;
  border: 1px dashed transparent;
  cursor: move;
}

.step-item:hover {
  border: 1px dashed #aaa;
  background-color: #fff;
}

.step-item:hover .tip-icon {
  visibility: visible;
}

.tip-icon {
  visibility: hidden;
}

.tip-popover {
  padding: 5px;
  min-width: 10px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.step-span {
  display: inline-block;
  max-width: 300px;
  word-wrap: break-word;
  color: #4a4a4a;
}

.mb40 {
  margin-bottom: 40px;
}

.email-attachments {
  display: flex;
  font-size: 14px;
}

.attachments-box {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
}

.attachments-box p {
  margin: 0 50px 20px 0;
}

.task-content {
  max-width: 500px;
  line-height: 1.5;
  color: #858585;
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-all;
}

.task-content a {
  cursor: auto;
  color: #ff7315;
  text-decoration: none;
}
.task-content table td,
.task-content table th {
  border: 1px solid #dcdfe6;
  padding: 4px 10px;
}
.source-name {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.task-name_box {
  position: relative;
}

.prefix-span {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  color: #000;
  font-size: 16px;
}

.task-tit——input {
  width: 268px;
}

.task-tit——input >>> .el-input__inner {
  padding-left: 44px;
  font-size: 16px;
}

.w268 {
  width: 268px;
}

.assign-user_box {
  margin-left: 32px;
}

.assign-user_box img {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.assign-user_box span {
  width: 300px;
  display: inline-block;
  color: #1c1c1e;
  font-size: 14px;
}

.user-option_box {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.user-option_box i {
  visibility: hidden;
}

.user-option_box span {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  cursor: pointer;
}

.user-option_box .ellipsis {
  display: inline-block;
  width: 200px;
}

.user-option_box span:hover {
  color: #ff7315;
}

.assign-icon {
  margin-right: 10px;
}

.check-user {
  color: #ff7315;
}

.check-user i {
  visibility: visible;
}

/* 标签面板 */
.tags-cascader >>> .el-cascader-menu:nth-child(2) .el-cascader-menu__list {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
}

.mt20 {
  margin-top: 20px;
}

.w378 {
  width: 378px;
}

.task_title {
  color: #505050;
  font-size: 18px;
  font-weight: 600;
}

.comment-tit {
  font-size: 18px;
  color: #505050;
  font-weight: 600;
  margin: 18px 0;
}

.comment-btn {
  background: #ff7315;
  border-color: #ff7315;
}

.comment-btn:hover {
  background: #ff7315;
  border-color: #ff7315;
  opacity: 0.9;
}

.reply-content {
  padding-left: 30px;
  display: flex;
}

.reply-content .user-name {
  margin-right: 5px;
}

.reply-content_detail {
  max-width: 415px;
  display: inline-block;
  word-break: break-all;
}

.float-top {
  position: fixed;
  left: 1066px;
  top: 10%;
}

.mr32 {
  margin-right: 32px;
}

.finish-num,
.el-progress__text {
  font-size: 16px !important;
}

.list_tit,
.finish-num {
  display: inline-block;
  line-height: 18px;
}

.log-h-container {
  height: 200px;
  overflow: hidden;
  position: relative;
}
.log-h-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 550px;
  height: 25px;
  background: linear-gradient(rgba(255, 255, 255, 0.001), white);
  pointer-events: none;
}
.more {
  color: #ff7315;
  font-size: 14px;
}
.handel-inner {
  line-height: 34px;
}
.user-label {
  display: block;
  max-width: 180px;
}
.disable-span {
  display: inline-block;
  text-decoration: line-through;
  color: #c0c4cc;
}
.header {
  width: 600px;
}
.edit-btn {
  font-size: 16px;
  color: #666666;
}
.edit-btn span {
  font-weight: 600;
}
.edit-btn i {
  color: #ff7315;
}
.tit-status {
  font-size: 18px;
  color: #606266;
}
.user-img_box {
  display: flex;
  flex-wrap: wrap;
  /* width: 400px; */
}
.editor-outbox {
  position: relative;
}
.task-source_tit {
  position: absolute;
  left: 10px;
  top: 30px;
}
.editor-outbox .w-e-text {
  padding-top: 8px;
}
.tips-dialog >>> .el-dialog__body {
  min-height: 60px;
  text-align: center;
}
</style>